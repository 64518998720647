import Icon from "@/component/utils/svg/Icon";
import Icon2 from "@/component/utils/svg/Icon2";
import Icon3 from "@/component/utils/svg/Icon3";
import Icon4 from "@/component/utils/svg/Icon4";
import { title } from "process";

const shopData = [
  {
    id: 1,
    img: "/assets/img/products/new-claim-500x500.webp",
    name: "New Claim Organic Pesticide",
    slug: "new-claim-organic-pesticide",
    price: 50,
    prevPrice: 160,
    discount: true,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "DEEP LILAC",
  },
  {
    id: 2,
    img: "/assets/img/products/trishul-organic-pesticides-500x500.webp",
    name: "Vegan Hand Cream",
    slug: "vegan-hand-cream",
    price: 235,
    prevPrice: 260,
    popularity: 25,
    rating: 3.5,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "CORAL RED",
  },
  {
    id: 3,
    img: "/assets/img/products/bio-kill-500x500.webp",
    name: "Plant-Based Deodorant",
    slug: "plant-based-deodorant",
    price: 350,
    prevPrice: 360,
    discount: true,
    popularity: 18,
    rating: 5,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "DEEP MULBERRY",
  },
  {
    id: 4,
    img: "/assets/img/products/new-claim-pesticides-500x500.webp",
    name: "Biodegradable Sunscreen",
    slug: "biodegradable-sunscreen",
    price: 400,
    prevPrice: 420,
    discount: true,
    popularity: 9,
    rating: 4,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 5,
    img: "/assets/img/products/bio-pesticides-500x500.webp",
    name: "Organic Toothpaste",
    slug: "organic-toothpaste",
    price: 300,
    prevPrice: 315,
    popularity: 14,
    rating: 3,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "TANGERINE",
  },
  {
    id: 6,
    img: "/assets/img/products/chemical-pesticide-500x500.webp",
    name: "Sustainable Body Lotion",
    slug: "sustainable-body-lotion",
    price: 540,
    prevPrice: 550,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "PORCELAIN",
  },
  {
    id: 7,
    img: "/assets/img/products/gibberellic-acid-500x500.webp",
    name: "Herbal Hair Oil",
    slug: "herbal-hair-oil",
    price: 180,
    prevPrice: 220,
    discount: true,
    popularity: 18,
    rating: 3,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "DEEP MULBERRY",
  },
  {
    id: 8,
    img: "/assets/img/products/bio-insecticides-500x500.webp",
    name: "Eco-Friendly Shampoo",
    slug: "eco-friendly-shampoo",
    price: 220,
    prevPrice: 230,
    discount: true,
    popularity: 15,
    rating: 5,
    quantity: 1,
    category: "AGRI-PESTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 9,
    img: "/assets/img/products/blast-500x500.webp",
    name: "Natural Face Scrub",
    slug: "natural-face-scrub",
    price: 250,
    prevPrice: 320,
    discount: true,
    popularity: 11,
    rating: 4.5,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "CORAL RED",
  },
  {
    id: 10,
    img: "/assets/img/products/super-kargil-insecticides-500x500.webp",
    name: "Organic Lip Balm",
    slug: "organic-lip-balm",
    price: 450,
    prevPrice: 480,
    popularity: 5,
    rating: 2,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 11,
    img: "/assets/img/products/bio-pesticides-solvent-and-emulsifier-500x500.webp",
    name: "Pure Lip Tint",
    slug: "pure-lip-tint",
    price: 350,
    prevPrice: 370,
    discount: true,
    popularity: 21,
    rating: 4,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "DEEP MULBERRY",
  },
  {
    id: 12,
    img: "/assets/img/products/capital-bio-insecticide-500x500.webp",
    name: "Glowing Skin Serum",
    slug: "glowing-skin-serum",
    price: 220,
    prevPrice: 250,
    popularity: 14,
    rating: 4,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "PORCELAIN",
  },
  {
    id: 13,
    img: "/assets/img/products/flymate-500x500.webp",
    name: "Botanical Cheek Stain",
    slug: "botanical-cheek-stain",
    price: 180,
    prevPrice: 185,
    discount: true,
    popularity: 14,
    rating: 3,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "DEEP LILAC",
  },
  {
    id: 14,
    img: "/assets/img/products/capital-bio-insecticides-500x500.webp",
    name: "Organic Eyebrow Gel",
    slug: "organic-eyebrow-gel",
    price: 350,
    prevPrice: 360,
    popularity: 25,
    rating: 3.5,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "DEEP LILAC",
  },
  {
    id: 15,
    img: "/assets/img/products/galaxy-insecticides-500x500.webp",
    name: "Nourishing Hair Mask",
    slug: "nourishing-hair-mask",
    price: 450,
    prevPrice: 460,
    discount: true,
    popularity: 18,
    rating: 5,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "CORAL RED",
  },
  {
    id: 16,
    img: "/assets/img/products/jaanvi-agriculture-insecticides-500x500.webp",
    name: "Pure Lip Tint 2",
    slug: "pure-lip-tint-2",
    price: 150,
    prevPrice: 180,
    discount: true,
    popularity: 9,
    rating: 4,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "CORAL RED",
  },
  {
    id: 17,
    img: "/assets/img/products/biofly-500x500.webp",
    name: "Soothing Lip Scrub",
    slug: "soothing-lip-scrub",
    price: 300,
    prevPrice: 320,
    popularity: 14,
    rating: 3,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "TANGERINE",
  },
  {
    id: 18,
    img: "/assets/img/products/attack-insecticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 19,
    img: "/assets/img/products/polo-super-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-INSECTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 20,
    img: "/assets/img/products/bhumi-power-l-plant-growth-promoters-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FERTILIZER",
    color: "GRAPHITE BROWN",
  },
  {
    id: 21,
    img: "/assets/img/products/bumper-gold-plant-growth-regulators-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FERTILIZER",
    color: "GRAPHITE BROWN",
  },
  {
    id: 22,
    img: "/assets/img/products/multi-micro-micronutrent-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FERTILIZER",
    color: "GRAPHITE BROWN",
  },
  {
    id: 23,
    img: "/assets/img/products/bhu-power-l-plant-growth-promoters-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FERTILIZER",
    color: "GRAPHITE BROWN",
  },
  {
    id: 24,
    img: "/assets/img/products/humic-gold-humic-acid-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 25,
    img: "/assets/img/products/Promolin-500x500.webp",
    name: "Promolin",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 26,
    img: "/assets/img/products/kashi-gold-amino-plus-humic-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 27,
    img: "/assets/img/products/prime-nova-humic-acid-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 28,
    img: "/assets/img/products/life-100-humic-acid-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 29,
    img: "/assets/img/products/humic-plus-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 30,
    img: "/assets/img/products/aminax-amino-acid-fertilizer-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-PLUS-HUMIC",
    color: "GRAPHITE BROWN",
  },
  {
    id: 31,
    img: "/assets/img/products/proton-larvicide-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-PESTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 32,
    img: "/assets/img/products/king-killer-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-PESTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 33,
    img: "/assets/img/products/dhanush-super-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-PESTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 34,
    img: "/assets/img/products/bio-spark-biopesticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-PESTICIDES",
    color: "GRAPHITE BROWN",
  },
  {
    id: 35,
    img: "/assets/img/products/speed-bio-controller-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-INSECTICIDE",
    color: "GRAPHITE BROWN",
  },
  {
    id: 36,
    img: "/assets/img/products/chloropyriphos-50-cypermethrin-5-ec-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-INSECTICIDE",
    color: "GRAPHITE BROWN",
  },
  {
    id: 37,
    img: "/assets/img/products/bio-insecticides-500x500 (1).webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-INSECTICIDE",
    color: "GRAPHITE BROWN",
  },
  {
    id: 38,
    img: "/assets/img/products/beat-47-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "ORGANIC-PESTICIDE",
    color: "GRAPHITE BROWN",
  },
  {
    id: 39,
    img: "/assets/img/products/new-claim-sg-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "ORGANIC-PESTICIDE",
    color: "GRAPHITE BROWN",
  },
  {
    id: 40,
    img: "/assets/img/products/organic-pesticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "ORGANIC-PESTICIDE",
    color: "GRAPHITE BROWN",
  }, {
    id: 41,
    img: "/assets/img/products/emamectin-benzoate-1-9-ec-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "PLANT-GROWTH-PROMOTERS",
    color: "GRAPHITE BROWN",
  }, {
    id: 42,
    img: "/assets/img/products/sugar-plus-plant-growth-promoters-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "PLANT-GROWTH-PROMOTERS",
    color: "GRAPHITE BROWN",
  }, {
    id: 43,
    img: "/assets/img/products/super-nova-plant-growth-fertilizer-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "PLANT-GROWTH-PROMOTERS",
    color: "GRAPHITE BROWN",
  }, {
    id: 44,
    img: "/assets/img/products/bio-miticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-MITICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 45,
    img: "/assets/img/products/boxer-organic-pesticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-MITICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 46,
    img: "/assets/img/products/bio-power-organic-miticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "ORGANIC-MITICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 47,
    img: "/assets/img/products/bio-seal-bio-miticide-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "ORGANIC-MITICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 48,
    img: "/assets/img/products/super-king-emamectine-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "EMAMECTIN-BENZOATE",
    color: "GRAPHITE BROWN",
  }, {
    id: 49,
    img: "/assets/img/products/yoddha-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "EMAMECTIN-BENZOATE",
    color: "GRAPHITE BROWN",
  }, {
    id: 50,
    img: "/assets/img/products/bio-shakti-flowering-stimulant-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FLOWERING-STIMULANT",
    color: "GRAPHITE BROWN",
  }, {
    id: 51,
    img: "/assets/img/products/grow-plus-nitro-benizene-20-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FLOWERING-STIMULANT",
    color: "GRAPHITE BROWN",
  }, {
    id: 52,
    img: "/assets/img/products/vitara-plus-flowering-stimulant-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "FLOWERING-STIMULANT",
    color: "GRAPHITE BROWN",
  }, {
    id: 53,
    img: "/assets/img/products/green-belt-micronutrient-fertilizers-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "MIX-MICRO-NUTRIENTS",
    color: "GRAPHITE BROWN",
  }, {
    id: 54,
    img: "/assets/img/products/humic-acid-powder-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "HUMIC-ACID",
    color: "GRAPHITE BROWN",
  }, {
    id: 55,
    img: "/assets/img/products/venus-virus-controller-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "VIRUS-CONTROLLER",
    color: "GRAPHITE BROWN",
  }, {
    id: 56,
    img: "/assets/img/products/lifeline-bio-fungicide-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BIO-FUNGICIDE",
    color: "GRAPHITE BROWN",
  }, {
    id: 57,
    img: "/assets/img/products/product-jpeg-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "BPH",
    color: "GRAPHITE BROWN",
  }, {
    id: 58,
    img: "/assets/img/products/bio-star-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-BIOPESTICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 59,
    img: "/assets/img/products/virat-bio-pesticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRI-BIOPESTICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 60,
    img: "/assets/img/products/advance-stick-sticker-and-spreader-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "STICKER-AND-SPREADER",
    color: "GRAPHITE BROWN",
  }, {
    id: 61,
    img: "/assets/img/products/lock-up-insecticides-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "INSECTICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 62,
    img: "/assets/img/products/product-jpeg-500x500 (1).webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "INSECTICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 63,
    img: "/assets/img/products/neemaz-neem-oil-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "NEEMAZ-ORGANIC-NEEM-OIL",
    color: "GRAPHITE BROWN",
  }, {
    id: 64,
    img: "/assets/img/products/fisto-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "LARVICIDE",
    color: "GRAPHITE BROWN",
  }, {
    id: 65,
    img: "/assets/img/products/chille-kum-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "CHILLI-KUM",
    color: "GRAPHITE BROWN",
  }, {
    id: 66,
    img: "/assets/img/products/humic-shiney-ball-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "SHINEY-BALL",
    color: "GRAPHITE BROWN",
  }, {
    id: 67,
    img: "/assets/img/products/bio-flower-nitro-benizene-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "NITROBENZINE",
    color: "GRAPHITE BROWN",
  }, {
    id: 68,
    img: "/assets/img/products/ferterra-bhukamp--500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRO-INSECTICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 69,
    img: "/assets/img/products/product-jpeg-500x500 (2).webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AGRO-INSECTICIDES",
    color: "GRAPHITE BROWN",
  }, {
    id: 70,
    img: "/assets/img/products/nitrobenzene-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "PEST-CONTROL-CHEMICALS",
    color: "GRAPHITE BROWN",
  }, {
    id: 71,
    img: "/assets/img/products/locket-amino-acids-500x500.webp",
    name: "Natural Eyelash Serum",
    slug: "natural-eyelash-serum",
    price: 400,
    prevPrice: 455,
    popularity: 10,
    rating: 4,
    quantity: 1,
    category: "AMINO-ACID",
    color: "GRAPHITE BROWN",
  }
];

const testimonialData = [
  {
    id: 1,
    mainImg: "/assets/img/testimonial/home-6-1.png",
    testimony:
      "Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis. Vestibulum molestie bibendum turpis luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum Mauris sit amet Suspends potent vestibulu lacinia est”",
    name: "Douglas Lyphe",
    designation: "Director, Client Experience",
  },
  {
    id: 2,
    mainImg: "/assets/img/testimonial/home-6-1.png",
    testimony:
      "Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis. Vestibulum molestie bibendum turpis luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum Mauris sit amet Suspends potent vestibulu lacinia est”",
    name: "Douglas Lyphe",
    designation: "Director, Client Experience",
  },
  {
    id: 3,
    mainImg: "/assets/img/testimonial/home-6-1.png",
    testimony:
      "Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis. Vestibulum molestie bibendum turpis luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum Mauris sit amet Suspends potent vestibulu lacinia est”",
    name: "Douglas Lyphe",
    designation: "Director, Client Experience",
  },
];

const pricingData = [
  {
    id: 1,
    plan: "Basic Plan",
    desc: "Denim aliquot abitur poseurs locus..!",
    price: 59,
    benefits: [
      "Check the health of plants",
      "Water properly",
      "Treat your soil",
      "Destroy the weeds",
      "Try raised beds",
    ],
  },
  {
    id: 2,
    plan: "Standard Plan",
    desc: "Denim aliquot abitur poseurs locus..!",
    price: 79,
    benefits: [
      "Free Landscape Design",
      "Destroy the weeds",
      "Water properly",
      "Treat your soil",
      "Try raised beds",
    ],
  },
  {
    id: 3,
    plan: "Ultimate Plan",
    desc: "Denim aliquot abitur poseurs locus..!",
    price: 99,
    benefits: [
      "Treat your soil",
      "Check the health of plants",
      "Water properly",
      "Destroy the weeds",
      "Try raised beds",
    ],
  },
];

const portfolioData = [
  {
    id: 1,
    mainImg: "/assets/img/team/home-6-portfolio-1.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 2,
    mainImg: "/assets/img/team/home-6-portfolio-2.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 3,
    mainImg: "/assets/img/team/home-6-portfolio-3.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 4,
    mainImg: "/assets/img/team/home-6-portfolio-4.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 5,
    mainImg: "/assets/img/team/home-6-portfolio-3.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 6,
    mainImg: "/assets/img/team/home-6-portfolio-4.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 7,
    mainImg: "/assets/img/team/home-6-portfolio-2.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
  {
    id: 8,
    mainImg: "/assets/img/team/home-6-portfolio-1.png",
    desc: "Rubbish Removal",
    title: "Tree Cleaning",
  },
];

const teamData = [
  {
    id: 1,
    mainImg: "/assets/img/team/home-6-teem-1.png",
    socials: [
      {
        icon: "fab fa-facebook-f",
        url: "#",
      },
      {
        icon: "fab fa-twitter",
        url: "#",
      },
      {
        icon: "fab fa-linkedin-in",
        url: "#",
      },
    ],
    designation: "Garden Experts",
    name: "Alexander Bennett",
    dropText: "LANDSCAPER",
  },
  {
    id: 2,
    mainImg: "/assets/img/team/home-6-teem-2.png",
    socials: [
      {
        icon: "fab fa-facebook-f",
        url: "#",
      },
      {
        icon: "fab fa-twitter",
        url: "#",
      },
      {
        icon: "fab fa-linkedin-in",
        url: "#",
      },
    ],
    designation: "Garden Experts",
    name: "Sophia Rodriguez",
    dropText: "LANDSCAPER",
  },
  {
    id: 3,
    mainImg: "/assets/img/team/home-6-teem-3.png",
    socials: [
      {
        icon: "fab fa-facebook-f",
        url: "#",
      },
      {
        icon: "fab fa-twitter",
        url: "#",
      },
      {
        icon: "fab fa-linkedin-in",
        url: "#",
      },
    ],
    designation: "Garden Experts",
    name: "Emily Nguyen",
    dropText: "LANDSCAPER",
  },
];

const blogData = [
  {
    id: 1,
    mainImg: "/assets/img/blog/home-6-blog-1.png",
    date: "June 24, 2023",
    title: "Blossom & Bloom: Exploring The Garden World.",
    desc: "Enim aliquam, vehicula sem at, luctus risus estibulum ultrices molestie.",
  },
  {
    id: 2,
    mainImg: "/assets/img/blog/home-6-blog-2.png",
    date: "June 24, 2023",
    title: "Breathing Easier with Home Air-Purifying Plants.",
    desc: "Enim aliquam, vehicula sem at, luctus risus estibulum ultrices molestie.",
  },
  {
    id: 3,
    mainImg: "/assets/img/blog/home-6-blog-3.png",
    date: "June 24, 2023",
    title: "Harvest Haven Nurturing your Green Spaces.",
    desc: "Enim aliquam, vehicula sem at, luctus risus estibulum ultrices molestie.",
  },
];

const categoryData = [
  {
    id: 1,
    mainImg: "/assets/img/rv-12-cate-1.jpg",
    category: "Cinnamon Tea",
    desc: "Cinnamon tea is typically made steeping cinnamon bark.",
  },
  {
    id: 2,
    mainImg: "/assets/img/rv-12-cate-2.jpg",
    category: "Black Tea",
    desc: " Black tea is a type of tea that fully oxidized, resulting.",
  },
  {
    id: 3,
    mainImg: "/assets/img/rv-12-cate-3.jpg",
    category: "Lemon Tea",
    desc: " Lemon tea offers a delightful combination of the natural.",
  },
  {
    id: 4,
    mainImg: "/assets/img/rv-12-cate-4.jpg",
    category: "Green Tea",
    desc: "Green tea contains catechins, powerful antioxidants.",
  },
];

const projectData = [
  "https://i.ibb.co/D9LJPF3/rv-12-project-1.gif",
  "https://i.ibb.co/2nLGdRs/rv-12-project-2.gif",
  "https://i.ibb.co/DR4cHM6/rv-12-project-3.gif",
  "https://i.ibb.co/D9LJPF3/rv-12-project-1.gif",
  "https://i.ibb.co/2nLGdRs/rv-12-project-2.gif",
];

const testimonialData2 = [
  {
    desc: "“Suspendisse in consectetur justo. Suspends molestee lorem accusant mattis rutrum. Nunc facilisis ultricies mi, et porta diam lobortis non. In hac habitasse platea dictumst”",
    thumbImg: "/assets/img/rv-3-testimony-1.png",
  },
  {
    desc: "“Suspendisse in consectetur justo. Suspends molestee lorem accusant mattis rutrum. Nunc facilisis ultricies mi, et porta diam lobortis non. In hac habitasse platea dictumst”",
    thumbImg: "/assets/img/rv-3-testimony-2.png",
  },
  {
    desc: "“Suspendisse in consectetur justo. Suspends molestee lorem accusant mattis rutrum. Nunc facilisis ultricies mi, et porta diam lobortis non. In hac habitasse platea dictumst”",
    thumbImg: "/assets/img/rv-3-testimony-3.png",
  },
];

const partnerData = [
  "/assets/img/rv-12-partner-1.png",
  "/assets/img/rv-12-partner-2.png",
  "/assets/img/rv-12-partner-3.png",
  "/assets/img/rv-12-partner-4.png",
  "/assets/img/rv-12-partner-5.png",
  "/assets/img/rv-12-partner-1.png",
];

const bannerData = [
  {
    subTitle: "Your Trust is Valued",
    title: "Safeguard Natural Resources.",
    style: "rv-9-banner-slide",
  },
  {
    subTitle: "Your Trust is Valued",
    title: "Safeguard Natural Resources.",
    style: "rv-9-banner-slide rv-9-banner-slide--2",
  },
  {
    subTitle: "Your Trust is Valued",
    title: "Safeguard Natural Resources.",
    style: "rv-9-banner-slide rv-9-banner-slide--3",
  },
];

const projectData2 = [
  {
    id: 1,
    mainImg: "/assets/img/rv-9-project-1.jpg",
    subTitle: "Protecting Nature",
    title: "Forest & Tree Planting",
    slug: "forest-&-tree-planting",
  },
  {
    id: 2,
    mainImg: "/assets/img/rv-9-project-2.jpg",
    subTitle: "Lawn Renovation",
    title: "Helping Young Planting",
    slug: "helping-young-planting",
  },
  {
    id: 3,
    mainImg: "/assets/img/rv-9-project-3.jpg",
    subTitle: "Environment",
    title: "Protecting Nature",
    slug: "protecting-nature",
  },
  {
    id: 4,
    mainImg: "/assets/img/rv-9-project-4.jpg",
    subTitle: "Wild Animals",
    title: "Save Water From Pollution",
    slug: "save-water-from-pollution",
  },
  {
    id: 5,
    mainImg: "/assets/img/rv-9-project-2.jpg",
    subTitle: "Lawn Renovation",
    title: "Helping Young Planting",
    slug: "helping-young-planting",
  },
  {
    id: 6,
    mainImg: "/assets/img/rv-9-project-3.jpg",
    subTitle: "Environment",
    title: "Protecting Nature",
    slug: "protecting-nature",
  },
];

const teamData2 = [
  {
    id: 1,
    mainImg: "/assets/img/rv-9-member-1.jpg",
    socials: [
      {
        url: "#",
        icon: "fa-brands fa-facebook-f",
      },
      {
        url: "#",
        icon: "fa-brands fa-twitter",
      },
      {
        url: "#",
        icon: "fa-brands fa-linkedin-in",
      },
    ],
    designation: "Team Leader",
    name: "Sophia Johnson",
  },
  {
    id: 2,
    mainImg: "/assets/img/rv-9-member-2.jpg",
    socials: [
      {
        url: "#",
        icon: "fa-brands fa-facebook-f",
      },
      {
        url: "#",
        icon: "fa-brands fa-twitter",
      },
      {
        url: "#",
        icon: "fa-brands fa-linkedin-in",
      },
    ],
    designation: "Team Leader",
    name: "Alexander Smith",
  },
  {
    id: 3,
    mainImg: "/assets/img/rv-9-member-3.jpg",
    socials: [
      {
        url: "#",
        icon: "fa-brands fa-facebook-f",
      },
      {
        url: "#",
        icon: "fa-brands fa-twitter",
      },
      {
        url: "#",
        icon: "fa-brands fa-linkedin-in",
      },
    ],
    designation: "Team Leader",
    name: "Olivia Martinez",
  },
  {
    id: 4,
    mainImg: "/assets/img/rv-9-member-1.jpg",
    socials: [
      {
        url: "#",
        icon: "fa-brands fa-facebook-f",
      },
      {
        url: "#",
        icon: "fa-brands fa-twitter",
      },
      {
        url: "#",
        icon: "fa-brands fa-linkedin-in",
      },
    ],
    designation: "Team Leader",
    name: "Sophia Johnson",
  },
];

const blogData2 = [
  {
    id: 1,
    mainImg: "/assets/img/rv-9-blog-1.jpg",
    date: "June 15, 2023",
    comments: 5,
    title: "Technology & Solutions For Saving The Environment.",
    slug: "technology-&-solutions-for-saving-the-environment",
    desc: "Fusce tincidunt nisi condimentum amet laoreet leo eleifend.",
  },
  {
    id: 2,
    mainImg: "/assets/img/rv-9-blog-2.jpg",
    date: "June 15, 2023",
    comments: 5,
    title: "Techniques to Ensure The Continuity of Wildlife.",
    slug: "techniques-to-ensure-the-continuity-of-wildlife",
    desc: "Fusce tincidunt nisi condimentum amet laoreet leo eleifend.",
  },
  {
    id: 3,
    mainImg: "/assets/img/rv-6-blog-1.jpg",
    date: "June 15, 2023",
    comments: 5,
    title: "3 Incentives For Emphasizing Community Building.",
    slug: "3-incentives-for-emphasizing-community-building",
    desc: "Fusce tincidunt nisi condimentum amet laoreet leo eleifend.",
  },
];

const galleryData = [
  "/assets/img/rv-9-gallery-1.jpg",
  "/assets/img/rv-9-gallery-2.jpg",
  "/assets/img/rv-9-gallery-3.jpg",
  "/assets/img/rv-9-gallery-4.jpg",
  "/assets/img/rv-9-gallery-5.jpg",
];
const speakersData = [
  {
    id: 1,
    videoSrc: "/assets/img/speaker-1.mp4",
    role: "Lead Speaker",
    name: "Thomas R. Toe",
  },
  {
    id: 2,
    videoSrc: "/assets/img/speaker-2.mp4",
    role: "Developer Expert",
    name: "Brandon Guidelines",
  },
  {
    id: 3,
    videoSrc: "/assets/img/speaker-3.mp4",
    role: "Founder, Marks",
    name: "Spruce Springclean",
  },
  {
    id: 4,
    videoSrc: "/assets/img/speaker-4.mp4",
    role: "Developer Expert",
    name: "Hermann P. Schnitzel",
  },
  {
    id: 5,
    videoSrc: "/assets/img/speaker-1.mp4",
    role: "Lead Speaker",
    name: "Thomas R. Toe",
  },
];

const serviceData = [
  {
    id: 1,
    title: "Agricultural Pesticides",
    description:
      "Agricultural pesticides are chemical or biological substances used to manage and control pests that affect crop production. They help in protecting crops from insects, weeds, fungi, and other harmful organisms.",
    slug: "agricultural-pesticides",
    icon: Icon,
    img: "/assets/img/service-details-1.jpg",
  },
  {
    id: 2,
    title: "Agricultural Insecticides",
    description:
      "Agricultural insecticides are chemical or biological agents specifically designed to kill or repel insects that can damage crops. They play a crucial role in pest management and help in protecting crops from insect infestations.",
    slug: "agricultural-insecticides",
    icon: Icon2,
    img: "/assets/img/service-details-2.jpg",
  },
  {
    id: 3,
    title: "Plant Growth Fertilizer",
    description:
      "Plant growth fertilizers are substances that provide essential nutrients to plants, promoting their healthy growth and development. They can be organic or inorganic and are designed to supply the necessary elements that plants need but may not get sufficiently from the soil.",
    slug: "plant-growth-fertilizer",
    icon: Icon3,
    img: "/assets/img/service-details-3.jpg",
  },
  {
    id: 4,
    title: "Amino Plus Humic",
    description:
      "Amino Plus Humic is a specialized fertilizer combining amino acids and humic substances. This blend enhances nutrient uptake, stimulates plant growth, and improves soil health. ",
    slug: "amino-plus-humic",
    icon: Icon4,
    img: "/assets/img/service-details-4.jpg",
  },
  {
    id: 5,
    title: "Bio Pesticides",
    description:
      "Bio pesticides are natural substances derived from animals, plants, bacteria, or minerals that control pests through non-toxic mechanisms. They are environmentally friendly alternatives to synthetic chemical pesticides.",
    slug: "bio-pesticides",
    icon: Icon,
    img: "/assets/img/service-details-5.jpg",
  },
  {
    id: 6,
    title: "Bio Insecticide",
    description:
      "Bio insecticides are natural products or biological agents used to control insect pests. They are derived from natural sources such as plants, bacteria, fungi, or minerals, offering an environmentally friendly alternative to chemical insecticides.",
    slug: "ORGANIC-MITICIDES",
    icon: Icon2,
    img: "/assets/img/service-details-6.jpg",
  },
  {
    id: 7,
    title: "Organic Pesticide",
    description:
      "Organic pesticides are pest control substances derived from natural sources that are permissible in organic farming. They help manage pests without the use of synthetic chemicals, aligning with organic agriculture principles.",
    slug: "organic-pesticide",
    icon: Icon3,
    img: "/assets/img/service-details-7.jpg",
  },
  {
    id: 8,
    title: "Plant Growth Promoters",
    description:
      "Plant growth promoters are substances or microorganisms that enhance the growth, development, and overall health of plants. They work by improving nutrient uptake, stimulating root and shoot growth, and increasing the plant's resistance to environmental stressors.",
    slug: "graphic-design",
    icon: Icon4,
    img: "/assets/img/service-details-8.jpg",
  },
  {
    id: 9,
    title: "Agriculture Miticides",
    description:
      "Agriculture miticides are chemical or biological agents specifically formulated to control mite infestations in crops. Mites are small arthropods that can cause significant damage to plants by feeding on their tissues.",
    slug: "email-marketing",
    icon: Icon,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 10,
    title: "Organic Miticides",
    description:
      "Organic miticides are natural substances or biological agents used to control mite infestations in crops, adhering to organic farming principles. These miticides are derived from natural sources and are environmentally friendly alternatives to synthetic chemicals.",
    slug: "email-marketing",
    icon: Icon2,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 11,
    title: "Emamectin Benzoate",
    description:
      "Emamectin benzoate is an insecticide and miticide derived from the fermentation of the bacterium Streptomyces avermitilis. It is commonly used in agriculture to control a wide range of pests, particularly caterpillars and mites.",
    slug: "email-marketing",
    icon: Icon3,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 12,
    title: 'Flowering Stimulant',
    description:
      "Flowering stimulants are substances or treatments used to enhance the flowering process in plants. They help promote the initiation, development, and abundance of flowers, leading to increased fruit and seed production.",
    slug: "email-marketing",
    icon: Icon4,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 13,
    title: "Mix Micro Nutrients",
    description: "Mix micro nutrients are specialized fertilizers or supplements that contain essential trace elements required in small amounts for optimal plant growth and development. These nutrients support various physiological functions and help prevent deficiencies that can affect plant health and productivity.",
    slug: "agricultural-pesticides",
    icon: Icon,
    img: "/assets/img/service-details-1.jpg",
  },
  {
    id: 14,
    title: "Humic Acid",
    description:
      'Humic acid is a complex organic substance derived from the decomposition of plant and animal matter in soil. It is a key component of humus and plays a crucial role in enhancing soil health and plant growth.',
    slug: "agricultural-insecticides",
    icon: Icon2,
    img: "/assets/img/service-details-2.jpg",
  },
  {
    id: 15,
    title: "Virus Controller",
    description:
      'Virus controllers are substances or practices used to manage and mitigate the impact of viral infections in plants. They help prevent or reduce the spread of plant viruses, which can cause significant damage to crops and reduce yields.',
    slug: "plant-growth-fertilizer",
    icon: Icon3,
    img: "/assets/img/service-details-3.jpg",
  },
  {
    id: 16,
    title: "Bio Fungicide",
    description:
      'Bio fungicides are natural or biological agents used to control fungal diseases in plants. They are derived from living organisms or natural substances and provide an environmentally friendly alternative to synthetic fungicides.',
    slug: "amino-plus-humic",
    icon: Icon4,
    img: "/assets/img/service-details-4.jpg",
  },
  {
    id: 17,
    title: "BPH",
    description:
      'The Brown Planthopper (BPH), scientifically known as Nilaparvata lugens, is a significant pest of rice crops. It is known for its ability to cause severe damage by feeding on plant sap and transmitting various rice viruses.',
    slug: "bio-pesticides",
    icon: Icon,
    img: "/assets/img/service-details-5.jpg",
  },
  {
    id: 18,
    title: "Agricultural Biopesticides",
    description:
      'Agricultural biopesticides are natural or biological substances used to control pests and diseases in crops. They are derived from natural sources such as plants, microorganisms, or minerals and are employed as eco-friendly alternatives to synthetic chemical pesticides.',
    slug: "ORGANIC-MITICIDES",
    icon: Icon2,
    img: "/assets/img/service-details-6.jpg",
  },
  {
    id: 19,
    title: "Sticker And Spreader",
    description:
      'Stickers and spreaders are adjuvants used in agricultural applications to enhance the effectiveness of pesticides, herbicides, and other treatments. They help improve the application and performance of these products on plants.',
    slug: "organic-pesticide",
    icon: Icon3,
    img: "/assets/img/service-details-7.jpg",
  },
  {
    id: 20,
    title: "Insecticides",
    description:
      "Insecticides are chemicals or substances used to control or kill insects that are pests to crops, plants, and sometimes humans and animals. They work by targeting the insects' physiological processes, behaviors, or development stages.",
    slug: "graphic-design",
    icon: Icon4,
    img: "/assets/img/service-details-8.jpg",
  },
  {
    id: 21,
    title: "Neemaz Organic Neem Oil",
    description:
      "Neemaz Organic Neem Oil is a natural pest control product derived from the seeds of the neem tree (Azadirachta indica). It is used as a bio-pesticide and organic solution for managing various plant pests and diseases.",
    slug: "email-marketing",
    icon: Icon,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 22,
    title: "Larvicide",
    description:
      "Larvicides are substances used to target and kill the larval stages of insects, particularly those that are pests to crops, plants, or public health. They are commonly used to control pests before they mature into adults, which helps in managing pest populations and preventing damage.",
    slug: "email-marketing",
    icon: Icon2,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 23,
    title: "Chilli Kum",
    description:
      "Chilli Kum is an insecticide formulated using chili extracts and other natural components. It is designed to manage and control various insect pests on crops, leveraging the natural properties of chili peppers to deter and kill pests.",
    slug: "email-marketing",
    icon: Icon3,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 24,
    title: "shiney ball",
    description:
      "Shiney Ball is a type of pest control product designed to manage pests using reflective or shiny materials. These products exploit light and reflection to deter or repel pests, particularly insects.",
    slug: "email-marketing",
    icon: Icon4,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 25,
    title: "Nitrobenzine",
    description:
      "Nitrobenzine is a chemical compound that is sometimes used in agriculture and industry. It is a nitro-aromatic compound with the chemical formula C₆H₅NO₂. Nitrobenzine is primarily known for its use as a solvent and intermediate in chemical synthesis rather than as a direct agricultural product.",
    slug: "email-marketing",
    icon: Icon,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 26,
    title: "Agro Insecticides",
    description:
      "Agro insecticides are chemical or biological substances used in agriculture to control or eliminate insect pests that threaten crops and plants. They are essential for protecting agricultural yields and maintaining crop health.",
    slug: "email-marketing",
    icon: Icon2,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 27,
    title: "Pest Control Chemicals",
    description:
      "Pest control chemicals are substances used to manage and eliminate pests, including insects, rodents, fungi, and weeds, in agricultural, residential, and industrial settings. These chemicals help protect crops, property, and public health from the damage and risks posed by various pests.",
    slug: "email-marketing",
    icon: Icon3,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 28,
    title: "Amino Acid",
    description:
      "Amino acids are organic compounds that serve as the building blocks of proteins. They play crucial roles in various biological processes, including plant growth and development. In agriculture, amino acids are often used as supplements to enhance plant health and productivity.",
    slug: "email-marketing",
    icon: Icon4,
    img: "/assets/img/service-details-9.jpg",
  },
  {
    id: 29,
    title: "Crop Consultation",
    slug: "sprinkling-and-discharge",
    img: "/assets/img/service-details-10.jpg",
    imgMain: "/assets/img/services/Consultation.jpeg",
    imgIcon: "/assets/img/services/talk.png",
    dropText: "crop Care",
  },
  {
    id: 30,
    title: "Soil Testing",
    slug: "commercial-grounds-keeping",
    img: "/assets/img/service-details-11.jpg",
    imgMain: "/assets/img/services/standard-quality-control-concept-m.jpg",
    imgIcon: "/assets/img/services/home-6-service-icon-2.png",
    dropText: "farm Care",
  },
  {
    id: 31,
    title: "Pest and Disease Management",
    slug: "garden-reawakening",
    img: "/assets/img/service-details-12.jpg",
    imgMain: "/assets/img/services/DiseaseManagement.jpeg",
    imgIcon: "/assets/img/services/exterminator.png",
    dropText: "Best Result",
  },
  // {
  //   id: 13,
  //   img: "/assets/img/service-details-13.jpg",
  //   iconImg: "/assets/img/rv-9-service-1.png",
  //   title: "Lawn Renovation",
  //   slug: "lawn-renovation",
  //   desc: "Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla ullamcorper quis.",
  // },
  // {
  //   id: 14,
  //   img: "/assets/img/service-details-14.jpg",
  //   iconImg: "/assets/img/rv-9-service-2.png",
  //   title: "ECO Friendly",
  //   slug: "eco-friendly",
  //   desc: "Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla ullamcorper quis.",
  // },
  // {
  //   id: 15,
  //   img: "/assets/img/service-details-15.jpg",
  //   iconImg: "/assets/img/rv-9-service-3.png",
  //   title: "Free Pick Up",
  //   slug: "free-pick-up",
  //   desc: "Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla ullamcorper quis.",
  // },
];
const accordionData = [
  {
    id: 1,
    title: "How many hours of lessons do you need to pass?",
    desc: "Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis justo id.",
  },
  {
    id: 2,
    title: "How to Handle Tailgaters on the Road?",
    desc: "Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis justo id.",
  },
  {
    id: 3,
    title: "How many driving lessons do I need before test?",
    desc: "Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis justo id.",
  },
];
const projectData3 = [
  {
    id: 1,
    img: "/assets/img/rv-9-project-1.jpg",
    subTitle: "Protecting Nature",
    title: "Green Oasis Initiative",
    slug: "green-oasis-initiative",
  },
  {
    id: 2,
    img: "/assets/img/rv-9-project-2.jpg",
    subTitle: "Lawn Renovation",
    title: "Sustainable Planting Drive",
    slug: "sustainable-planting-drive",
  },
  {
    id: 3,
    img: "/assets/img/rv-9-project-3.jpg",
    subTitle: "Environment",
    title: "Ecological Harmony Project",
    slug: "ecological-harmony-project",
  },
  {
    id: 4,
    img: "/assets/img/rv-9-project-4.jpg",
    subTitle: "Wild Animals",
    title: "Aquatic Life Preservation",
    slug: "aquatic-life-preservation",
  },
  {
    id: 5,
    img: "/assets/img/rv-8-project-1.jpg",
    subTitle: "Protecting Nature",
    title: "Biodiversity Conservation",
    slug: "biodiversity-conservation",
  },
  {
    id: 6,
    img: "/assets/img/rv-8-project-3.jpg",
    subTitle: "Protecting Nature",
    title: "Eco-Forest Regeneration",
    slug: "eco-forest-regeneration",
  },
];

const teamData3 = [
  {
    id: 1,
    img: "/assets/img/profile/youngMan.png",
    subTitle: "Managing Director (M.D)",
    title: "Subhash Dholariya",
  },
  {
    id: 2,
    img: "/assets/img/profile/CEO.png",
    subTitle: "Chief Executive Officer (CEO)",
    // title: "john doe",
  },
  {
    id: 3,
    img: "/assets/img/profile/manager.png",
    subTitle: "General Manager (GM)",
    // title: "john doe",
  },
  // {
  //   id: 4,
  //   img: "/assets/img/rv-3-member-1.jpg",
  //   subTitle: "Marketing Coordinator",
  //   title: "Olivia Fisher",
  // },
  // {
  //   id: 5,
  //   img: "/assets/img/rv-3-member-2.jpg",
  //   subTitle: "Development Expert",
  //   title: "Ethan Turner",
  // },
  // {
  //   id: 6,
  //   img: "/assets/img/rv-3-member-3.jpg",
  //   subTitle: "Communications Officer",
  //   title: "Ava Hayes",
  // },
];

const productDetailsImage = [
  {
    thumbs: [
      "/assets/img/rv-12-pro-8.png",
      "/assets/img/rv-12-pro-5.png",
      "/assets/img/rv-12-pro-6.png",
      "/assets/img/rv-12-pro-7.png",
    ],
    main: [
      "/assets/img/rv-12-pro-8.png",
      "/assets/img/rv-12-pro-5.png",
      "/assets/img/rv-12-pro-6.png",
      "/assets/img/rv-12-pro-7.png",
    ],
  },
];

const blogData3 = [
  {
    id: 1,
    img: "/assets/img/org-blog-1.jpg",
    date: "June 15, 2023",
    comments: 5,
    title: "Stronger Communities Through Organic Engagement",
    slug: "stronger-communities-organic-engagement",
  },
  {
    id: 2,
    img: "/assets/img/org-blog-2.jpg",
    date: "July 20, 2023",
    comments: 12,
    title: "Mastering SEO: The Organic Approach",
    slug: "mastering-seo-organic-approach",
    big: true,
  },
  {
    id: 3,
    img: "/assets/img/org-blog-3.jpg",
    date: "August 1, 2023",
    comments: 8,
    title: "Attract & Retain with Organic Content Marketing",
    slug: "attract-retain-organic-content",
    small: true,
  },
  {
    id: 4,
    img: "/assets/img/org-blog-4.jpg",
    date: "September 10, 2023",
    comments: 3,
    title: "Finding Creative Flow in Organic Practices",
    slug: "finding-creative-flow-organic",
    big: true,
  },
  {
    id: 5,
    img: "/assets/img/org-blog-5.jpg",
    date: "October 5, 2023",
    comments: 15,
    title: "Build & Thrive: The Power of Organic Social Media",
    slug: "build-thrive-power-organic-social",
    small: true,
  },
  {
    id: 6,
    img: "/assets/img/org-blog-6.jpg",
    date: "November 1, 2023",
    comments: 7,
    title: "Boost Engagement: Insights into Organic Email Marketing",
    slug: "boost-engagement-insights-organic-email",
  },
  {
    id: 7,
    img: "/assets/img/org-blog-7.jpg",
    date: "December 15, 2023",
    comments: 20,
    title: "Productivity Hacks: Organic Methods for Entrepreneurs",
    slug: "productivity-hacks-organic-entrepreneurs",
    small: true,
  },
  {
    id: 8,
    img: "/assets/img/org-blog-8.jpg",
    date: "January 10, 2024",
    comments: 10,
    title: "Work Smarter Together: Mastering Collaboration",
    slug: "work-smarter-mastering-collaboration",
  },
  {
    id: 9,
    img: "/assets/img/org-blog-9.jpg",
    date: "February 2, 2024",
    comments: 4,
    title: "Cultivating Health with Organic Produce",
    slug: "cultivating-health-with-organic-procedure",
    big: true,
  },
  {
    id: 10,
    img: "/assets/img/org-blog-10.jpg",
    date: "December 15, 2023",
    category: "Organic",
    title: "Embrace Rainy Days With a Comforting Tea.",
    slug: "embrace-rainy-days-with-a-comforting-tea",
  },
  {
    id: 11,
    img: "/assets/img/org-blog-11.jpg",
    date: "January 10, 2024",
    category: "China Tea",
    title: "The Nature of Tea Captures Revitalizing Freshness.",
    slug: "the-nature-of-tea-captures-revitalizing-freshness",
  },
  {
    id: 12,
    img: "/assets/img/org-blog-12.jpg",
    date: "February 2, 2024",
    category: "Organic",
    title: "Tea Carries Natural Freshness Akin to The Outdoors.",
    slug: "tea-carries-natural-freshness-akin-to-the-outdoors",
  },
  {
    id: 13,
    img: "/assets/img/org-blog-13.jpg",
    mainImg: "/assets/img/rv-9-blog-1.jpg",
    comments: 5,
    date: "December 15, 2023",
    title: "Technology & Solutions For Saving The Environment.",
    slug: "technology-solutions-for-saving-the-environment",
  },
  {
    id: 14,
    img: "/assets/img/org-blog-14.jpg",
    mainImg: "/assets/img/rv-9-blog-2.jpg",
    comments: 5,
    date: "January 10, 2024",
    title: "Techniques to Ensure The Continuity of Wildlife.",
    slug: "techniques-to-ensure-the-continuity-of-wildlife",
  },
  {
    id: 15,
    img: "/assets/img/org-blog-15.jpg",
    mainImg: "/assets/img/rv-6-blog-1.jpg",
    comments: 5,
    date: "February 2, 2024",
    title: "3 Incentives For Emphasizing Community Building.",
    slug: "incentives-for-emphasizing-community-building.",
  },
];

const bannerData2 = [
  {
    id: 1,
    subTitle: "biozyne crop guard",
    title: "Protect Your Crops with Our Advanced Pesticide Range.",
    slideStyle: "rv-12-banner__slide",
  },
  {
    id: 2,
    subTitle: "biozyne crop guard",
    title: "Smart Solutions for Pest-Free Living.",
    slideStyle: "rv-12-banner__slide rv-12-banner__slide--2",
  },
  {
    id: 3,
    subTitle: "biozyne crop guard",
    title: "Guardians of Your Green Spaces.",
    slideStyle: "rv-12-banner__slide rv-12-banner__slide--3",
  },
];

export {
  shopData,
  serviceData,
  testimonialData,
  pricingData,
  portfolioData,
  teamData,
  blogData,
  categoryData,
  projectData,
  testimonialData2,
  partnerData,
  bannerData,
  projectData2,
  teamData2,
  blogData2,
  galleryData,
  speakersData,
  accordionData,
  projectData3,
  teamData3,
  productDetailsImage,
  blogData3,
  bannerData2,
};
